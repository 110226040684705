/* Popup.css */

.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .popup-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 300px;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
 
.popup-form-apply{
  position:fixed;
  border-radius:8px;
  top:50%;
  left:50%;
  z-index:9999;
  opacity:0;
  visibility:hidden;
  text-align:center;
  padding:16px 16px 16px;
  transform:translate(-50%,-50%);
  background-color:#c4b9b9;
  transition:opacity .25s ease,visibility 0s ease
}
.popup-form-apply .background-blue{
  position:relative;
  border-radius:8px;
  margin-bottom:20px;
  padding:30px 16px;
  background-color:#ffffff
}
.popup-form-apply .clone-form-apply,.popup-form-apply .clone-form-apply-session-timeout{
  position:absolute;
  top:-22px;
  right:-22px
}
.popup-form-apply .clone-button-form{
  display:block
}
.popup-form-apply .popup-footer h2{
  text-align:left;
  font-size:16px;
  line-height:1.625;
  font-weight:500;
  margin-bottom:20px
}
.popup-form-apply .popup-footer .help-contact ul{
  display:flex;
  align-items:center;
  justify-content:center;
  list-style:none;
  margin:0;
  padding:0
}
.popup-form-apply .popup-footer .help-contact ul li{
  margin:0 10px
}
.popup-form-apply .popup-footer .help-contact ul .icon{
  display:flex
}
.popup-form-apply .array-button{
  display:flex;
  justify-content:space-around
}
.popup-form-apply .array-button .button-popup{
  border-radius:8px;
  padding:11px 30px;
  font-size:16px;
  line-height:1.625;
  font-weight:500;
  color:#fff
}
.popup-form-apply .array-button .secondary{
  background-color:var(--secondary)
}
.popup-form-apply .array-button .primary{
  background-color:var(--primary)
}
.popup-form-apply.active{
  opacity:1;
  visibility:visible
}